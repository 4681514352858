<template>
  <CartConfirm />
</template>

<script>
import CartConfirm from '@/components/basic/cart/CartConfirm.vue'

export default {
  components: {
    CartConfirm,
  },
}
</script>
